<template>
  <div class="public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput
          v-model="formInline.bill_offline_no"
          placeholder="输入订单编号"
        >
          <template slot="pre">订单编号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="dateChange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item>
      <el-select v-model="formInline.money_status" clearable placeholder="金额状态">
        <el-option :value="10" label="正常"></el-option>
        <el-option :value="20" label="异常"></el-option>
      </el-select>
    </el-form-item> -->
      <el-form-item>
        <MyButton title="搜索" class="offset" @click="search">
          <template slot="preImage">
            <img src="../../../assets/img/icon/search.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item label>
        <MyButton :accessId="31221" @click="history">
          <template slot="preImage">
            <img src="../../../assets/img/his.png" alt="" />
          </template>
          <span>历史核销记录</span>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton
          :accessId="11716"
          @click="
            $exported(
              '/property/bill_offline/exportC ',
              formInline,
              '线上账单列表.xlsx'
            )
          "
        >
          <span>导出</span>
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <div class="public-table">
      <el-table
        :data="tableData"
        v-loading="loading"
        ref="tableRef"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="凭证号" prop="voucher_number"></el-table-column>
        <el-table-column
          label="收费时间"
          width="250px"
          prop="pay_time"
        ></el-table-column>
        <el-table-column
          label="实收金额"
          width="250px"
          prop="key_pay_price"
        ></el-table-column>
        <el-table-column
          label="手续费"
          width="250px"
          prop="bill_charge"
        ></el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <div class="slot-box">
              <pop
                tips="确认核销"
                :accessId="7994"
                @myclick="details(scope.row)"
              >
                <img class="icon" src="../../../assets/img/icon/quer.png" alt />
              </pop>
              <!-- <pop tips="取消核销">
                <img class="icon" src="../../../assets/img/icon/xiangqing.png" alt />
              </pop> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="summation">
        <div class="name">汇总核销</div>
        <div class="list">{{ (yesterdayData && yesterdayData.date) || 0 }}</div>
        <div class="list">
          {{ (yesterdayData && yesterdayData.pay_price) || 0 }}
        </div>
        <div class="list">
          {{ (yesterdayData && yesterdayData.actual_price) || 0 }}
        </div>
        <div class="list">
          <div class="slot-box">
            <pop
              tips="确认核销"
              :accessId="7994"
              @myclick="allCancelAfter"
              v-if="yesterdayData && yesterdayData.bill_total > 0"
            >
              <img class="icon" src="../../../assets/img/icon/quer.png" alt />
            </pop>
          </div>
        </div>
      </div>
      <div class="apiPage">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="formInline.pageSize"
          :current-page="formInline.pageNum"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="异常核对"
      :visible.sync="dialogVisible"
      width="880px"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="到账总金额" prop="actual_price">
          <el-input
            v-model="ruleForm.actual_price"
            class="public-input"
            placeholder="请输入到账总金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="异常原因">
          <el-input
            v-model="ruleForm.abnormal_reason"
            class="public-input"
            placeholder="请输入异常原因"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理方案">
          <el-input
            v-model="ruleForm.deal_plan"
            class="public-input"
            placeholder="请输入处理方案"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" class="offset" @click="affirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "accountcheck",
  data() {
    return {
      formInline: {
        bill_offline_no: "",
        pageSize: 10,
        pageNum: 1,
        payBeginTime: "",
        payEndTime: "",
        money_status: "",
        bill_status: 10,
        extract_status: 10,
        pay_type: -1,
      },
      total: 0,
      date: "",
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        bill_offline_ids: [],
        pay_type: -1,
        payBeginTime: "",
        payEndTime: "",
        abnormal_reason: "",
        deal_plan: "",
        actual_price: "",
      },
      rules: {
        actual_price: [
          { required: true, message: "请输入到账总金额", trigger: "blur" },
        ],
      },
      isAll: false,
      yesterday: {
        payBeginTime: "",
        payEndTime: "",
      },
      yesterdayData: null,
      loading: false,
    };
  },
  created() {
    let day1 = new Date();
    day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
    let terday = this.$common.formatDateTime(day1);
    this.yesterday.payBeginTime = terday;
    this.yesterday.payEndTime = terday;
    this.formInline.payBeginTime = terday;
    this.formInline.payEndTime = terday;
    console.log(this.formInline.payBeginTime);
    this.yesterday.pay_type = -1;
    this.getList();
    this.getListTotal();
  },
  filters: {
    billStatus(value) {
      switch (value) {
        case 10:
          return "未核销";
        case 20:
          return "已核销";
      }
    },
  },
  methods: {
    search() {
      this.getList();
      this.getListTotal();
    },
    getListTotal() {
      this.$request
        .HttpGet("/bill_offline/listExtractAll", this.yesterday)
        .then((res) => {
          this.yesterdayData = res.data;
          this.date = res.data.date.split("~");
        });
    },
    allCancelAfter() {
      this.isAll = true;
      this.dialogVisible = true;
    },
    history() {
      this.$router.push({
        path: "/finance/accountcheck/historyRecord",
        query: {
          type: 10,
        },
      });
    },
    affirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAll) {
            if (this.formInline.payBeginTime) {
              this.ruleForm.payBeginTime = this.formInline.payBeginTime;
              this.ruleForm.payEndTime = this.formInline.payEndTime;
            } else {
              this.ruleForm.payBeginTime = this.yesterday.payBeginTime;
              this.ruleForm.payEndTime = this.yesterday.payEndTime;
            }
          } else {
            this.ruleForm.payBeginTime = "";
            this.ruleForm.payEndTime = "";
          }
          this.$request
            .HttpPost("/bill_offline/setExtract", this.ruleForm)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.dialogVisible = false;
              this.search();
            });
        }
      });
    },
    getList(e = 1) {
      this.loading = true;
      this.formInline.pageNum = e;
      this.$request
        .HttpGet("/bill_offline/list", this.formInline)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dateChange(date) {
      console.log(date);
      this.formInline.payBeginTime = date ? date[0] : "";
      this.formInline.payEndTime = date ? date[1] : "";
      if (date) {
        this.yesterday.payBeginTime = date[0];
        this.yesterday.payEndTime = date[1];
      } else {
        this.yesterday.payBeginTime = "";
        this.yesterday.payEndTime = "";
      }
    },
    handleClose() {
      this.ruleForm = {
        bill_offline_ids: [],
        pay_type: -1,
        payBeginTime: "",
        payEndTime: "",
        abnormal_reason: "",
        deal_plan: "",
        actual_price: "",
      };
    },
    details(row) {
      this.isAll = false;
      this.ruleForm.bill_offline_ids = [row.bill_offline_id];
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.autoFlex {
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    align-items: center;
    .totalAmout {
      margin-right: 15px;
    }
  }
}

/deep/ .el-table .el-table__cell {
  text-align: center;
}
.summation {
  display: flex;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #ebeef5;
  div {
    // flex: 1;
    text-align: center;
  }
  .name {
    // width: 250px;
    flex: 1;
  }
  .time {
    // width: 200px;
  }
  .list {
    width: 250px;
  }
}
</style>
